<template>
  <div style="width: 100%; height: 100%; overflow: hidden">
    <!-- <el-menu router :default-active="activeIndex" mode="horizontal" @select="handleSelect">
      <el-menu-item v-for="(item, i) in menuList" :key="i" :index="item.path">
        {{ item.name }}
      </el-menu-item>
    </el-menu> -->
    <!-- <ul class="box center ul">
      <li v-for="(item, index) in menuList" :key="index" @click="changeTab(item)" class="pointer">
        <div>{{ item.name }}</div>
        <div class="imgBox">
          <img src="../../../assets/images/Rectangle_bar.png" alt="" v-if="active == item.id" />
          <img src="" alt="" v-if="active != item.id" />
        </div>
      </li>
    </ul> -->

    <!-- <div class="box justify mB28">
      <ul class="ulTab box center">
        <li v-for="(item, index) in menuList" :key="index" class="box center pointer" @click="changeTab(item)">
          <div style="width: 100%" class="fu">
            <div>{{ item.name }}</div>
            <div class="tiao" v-if="active == item.id"></div>
          </div>
          <div class="shu" v-if="index != menuList.length - 1"></div>
        </li>
      </ul>
    </div> -->
    <!-- <div class="jieduan"></div> -->

    <router-view style="overflow: hidden; height: 100%" :key="$route.path" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      currentPage: 1,
      menuList: [
        // { id: 1, name: "自重荷载", path: "/simulation" },
        // { id: 2, name: "温度荷载", path: "/temperature" },
        // { id: 3, name: "地震荷载", path: "/seismic" }
      ],
      activeIndex: "/healthy",
      active: "1"
    };
  },
  mounted() {},

  created() {
    // this.activeIndex = sessionStorage.getItem('warningMenuActiveIndex') || '/warning'
    this.active = sessionStorage.getItem("simulationMenuActiveIndex");
    // this.getMenuList();
    // console.log("this.active", this.active);
    // for (let i = 0; i < this.menuList.length; i++) {
    //   console.log(" this.menuList[i].id", this.menuList[i].id);
    //   if (this.active == this.menuList[i].id) {
    //     console.log("this.menuList[i].path", this.menuList[i].path);
    //     this.$router.push(this.menuList[i].path);
    //     return;
    //   }
    // }
  },
  methods: {
    setBreadcrumb(item) {
      let arr = this.$store.state.breadcrumbList;
      if (arr.length > 2) {
        arr.splice(2);
      }
      arr.push(item);
      this.$store.commit("getBreadcrumb", arr);
    },
    handleSelect(val) {
      sessionStorage.setItem("simulationMenuActiveIndex", val);
    },
    resetData() {
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },

    // 切换
    changeTab(item) {
      this.isRouterAlive = false;
      sessionStorage.setItem("simulationMenuActiveIndex", item.id);
      console.log("item", item, "this.menuList", this.menuList);
      this.active = item.id;

      this.setBreadcrumb(item);

      this.$router.push(item.path);
    },
    getMenuList() {
      let permissionId = JSON.parse(sessionStorage.getItem("SimulationPath")).id;
      // sessionStorage.setItem("SimulationPath", JSON.stringify(item));
      this.$axios
        .get(`${this.baseURL}auth/authPermission/user/all/two/${permissionId}`)
        .then((res) => {
          console.log("获取三级菜单-在线仿真", res);
          this.menuList = res.data.data;
          for (let i = 0; i < this.menuList.length; i++) {
            console.log(" this.menuList[i].id", this.menuList[i].id);
            if (this.active == this.menuList[i].id) {
              console.log("this.menuList[i].path", this.menuList[i].path);
              this.active = this.menuList[i].id;
              sessionStorage.setItem("simulationMenuActiveIndex", this.menuList[i].id);
              if (this.menuList[i].path == "/deadLoad") {
                this.menuList[i].path = this.menuList[i].path + "?active=1";
                // this.$router.push({ path: this.menuList[i].path + "?active=1" });
                // return;
              }
              if (this.menuList[i].path == "/temperature") {
                this.menuList[i].path = this.menuList[i].path + "?active=2";
                // this.$router.push({ path: this.menuList[i].path + "?active=2" });
                // return;
              }
              this.setBreadcrumb(this.menuList[i]);
              this.$router.push({ path: this.menuList[i].path });
              return;
            }
          }
          this.active = this.menuList[0].id;
          sessionStorage.setItem("simulationMenuActiveIndex", this.menuList[0].id);
          if (this.menuList[0].path == "/deadLoad") {
            this.menuList[0].path = this.menuList[0].path + "?active=1";

            // this.$router.push({ path: this.menuList[0].path + "?active=1" });
            // return;
          }
          if (this.menuList[0].path == "/temperature") {
            this.menuList[0].path = this.menuList[0].path + "?active=2";
            // this.$router.push({ path: this.menuList[0].path + "?active=2" });
            // return;
          }
          this.setBreadcrumb(this.menuList[0]);
          this.$router.push(this.menuList[0].path);
        })
        .catch((err) => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.jieduan {
  width: 100%;
  height: 1px;
  box-shadow: 0px -1px 4px 0px #ffffff;
  opacity: 0.32;
  border: 1px solid #1f61ff;
  margin-bottom: 10px;
  margin-top: 10px;
}

.ulTab {
  .fu {
    position: relative;
  }
  li {
    position: relative;
    cursor: pointer;
  }
  .tiao {
    position: absolute;
    top: 20px;
    width: 100%;
    height: 8px;
    background: url("../../assets/images/report/juxing.png");
    background-size: 100% 100%;
    // background-position: 50px 0;
  }
  .shu {
    margin: 0 30px;
    width: 1px;
    height: 20px;
    background: #ccc;
  }
}

.el-form {
  text-align: left;
  margin: 17px 0 24px;
  &::v-deep {
    .el-input {
      height: 40px;
    }
    .el-form-item {
      margin-bottom: 0;
    }
    .el-form-item:first-child {
      margin-right: 50px;
    }
    .el-form-item__label {
      color: #fff;
      padding-right: 10px;
    }
    .el-form-item__content {
      margin-left: 10px;
    }
    .el-button:not(:first-child) {
      margin-left: 20px;
    }
  }
}
.el-menu-item {
  color: #cfd1d1;
}
.el-table {
  margin-bottom: 35px;
}

.ul {
  li {
    padding-left: 22px;
    padding-right: 22px;
    font-size: 16px;
    z-index: 0;
  }
  .imgBox {
    img {
      height: 8px;
      width: 100%;
      position: relative;
      top: -8px;
      z-index: 0;
    }
  }
}
</style>
